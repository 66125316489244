import React, { Component } from 'react'
import SectionTitle from '../../components/SectionTitle'

import vec1 from '../../images/contact/1.png'
import vec2 from '../../images/contact/2.png'
import imageButton from "../../images/asistencia.png";

class RSVP extends Component {


    state = {
        name: '',
        email: '',
        address: '',
        meal: '',
        service: '',
        guest: '',
        error: {}
    }


    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    subimtHandler = (e) => {
        e.preventDefault();

        const { name,
            email,
            address,
            service,
            meal,
            guest, error } = this.state;

        if (name === '') {
            error.name = "Please enter your name";
        }
        if (email === '') {
            error.email = "Please enter your email";
        }
        if (address === '') {
            error.address = "Please enter your address";
        }
        if (service === '') {
            error.service = "Please Select your service";
        }
        if (guest === '') {
            error.guest = "Please Select your Guest List";
        }
        if (meal === '') {
            error.meal = "Select Select Your Meal";
        }


        if (error) {
            this.setState({
                error
            })
        }
        if (error.name === '' && error.email === '' && error.email === '' && error.service === '' && error.address === '' && error.meal === '' && error.guest === '') {
            this.setState({
                name: '',
                email: '',
                address: '',
                meal: '',
                guest: '',
                error: {}
            })
        }
    }

    render(){
        const { name,
            email,
            address,
            service,
            guest,
            meal,
            error } = this.state;

        return(
            <section className="wpo-story-section section-padding" id="RSVP">
                <div className="container">
                    <div className="container" style={{ maxWidth: "600px"}}>
                        <a href="https://forms.gle/LXvDAv4NZniqTQ1x9">
                            <img src={imageButton} />
                        </a>
                    </div>
                </div>
            </section>
        )
    }

}
export default  RSVP;