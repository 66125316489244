import React from 'react'
import {Link} from 'react-router-dom'
import SectionTitle from '../../components/SectionTitle'
import blogs from '../../api/blogs'

const BlogSection = (props) => {
    
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className={`wpo-blog-section section-padding`}>
            <SectionTitle MainTitle={'Nuestro mejor regalo'} />
            <div style={{ paddingRight: "20px", paddingLeft: "20px", textAlign: "ce"}} className="container" id="blog">

                Aquí es donde quedaría super bien decir el clásico “nuestro mejor regalo es vuestra asistencia”, pero sabemos que NO es así. Como diría Santi “los tiempos cambian, ya no hay listas de bodas en Vidosa, sólo quieren dinero”.
                <br /><br />
                Nuestro mejor regalo no es “dinero”, es que contribuyáis en el viaje de nuestros sueños. En kilómetros, en experiencias, en comidas, en souvenirs... en VIDA COMPARTIDA.
                <br /> <br />
                Vamos a disfrutar de un tiempo en Bali para descansar, disfrutar y coger pilas para luego patearnos Japón, el país de nuestros sueños. Los que nos conocéis, sabéis que desde el inicio de nuestra relación hemos hablado de este destino como el viaje de nuestras vidas. Somos un par de frikis, gamers, otakus, con pasión por la cultura, espiritualidad y la cocina japonesa ¿Qué más podemos pedir? ¿Y qué mejor momento que disfrutarlo en nuestra luna de miel? <br />
                <br /> <br />
                <strong>ES76 0186 1001 9305 2307 4078</strong>
            </div>

        </section>
    )
}

export default BlogSection;