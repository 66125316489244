import React, { useState } from 'react';
import SectionTitle from '../../components/SectionTitle'
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import sImg1 from '../../images/story/Foto_2.jpg'
import sImg2 from '../../images/story/2.jpg'
import sImg3 from '../../images/story/3.jpg'
import sImg4 from '../../images/story/4.jpg'

import sIcon1 from '../../images/story/1.png'
import sIcon2 from '../../images/story/2.png'
import sIcon3 from '../../images/story/3.png'
import sIcon4 from '../../images/story/4.png'


import Shape from '../../images/story/shape.jpg'


const StorySection = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }


    const Tabs = [
        {
            Id: '1',
            tabItem: '¿Cómo nos conocimos?',
        },
        {
            Id: '2',
            tabItem: '¿Porqué Nutrios?',
        },
        /*{
            Id: '3',
            tabItem: 'Marige Proposal',
        },
        {
            Id: '4',
            tabItem: 'Our Engagement',
        }*/
    ]

    const Story = [
        {
            Id: '1',
            sImg: sImg1,
            //sIcon:sIcon1,
            //Shape:Shape,
            //sTitle:'First Time We Meet',
            //date:'Sep 15,2022',
            description:"La gran mayoría de vosotros conoce nuestra historia porque la hemos contado mil y una veces, pero para los que queráis refrescar la memoria... aquí va un remember.",
            description2:"Nos conocimos el verano del 2017, en una fiesta de verano muy conocida de Mataró, los domingos de La Milönga. Esta es una fiesta de tardeo a la que José Luis y Leticia le tenían mucha afición, a él porque le quedaba cerca de la finca (“Llava”), y a ella, porque era una buena excusa para reencontrarse con amigos de la infancia de Sant Pol. Ese domingo, Leti reunió a un grupo de amigos de lo más heterogéneo en esa ocasión; a Javi, su hermano y Tania (amigos de Sant Pol), a Victor y Mercè (de la uni) y a Antonio (su mejor amigo ¿de verdad necesita presentación?).  Y JL... a los de siempre. ",
            description3:"Ese día fue rodado, y ya cerrando el tardeo a las 2 de la noche, Leticia fue a buscar la última consumición que le incluía la entrada. En la barra, un chico se le acercó, e invitó a la chica “con nombre de reina” (tirando de clásicos) y a sus amigas a unirse después de fiesta al Johnny’s … Sus amigas la retaron a que Leti le pidiera una ronda de chupitos a cambio (¡primera vez que he sugerido que me invitaran a una copa, lo prometo papá!). Y de repente, alguien de entre los amigos de ese chico, apareció por detrás, tomó el brazo de Leti, la apartó y le dijo “¿Estás segura de que quieres eso? Si le pides una copa, él esperará algo a cambio... y no te lo recomiendo”. Era José Luis. A Leticia le encantó su actitud protectora, le dio un beso fugaz y le dijo “¡Llámame mañana!",
            description4:"Y estuvieron semanas hablando y hablando por WhatsApp, con conversaciones muy profundas, viendo todo lo que les conectaba y lo mucho que le atraía la personalidad de uno y de otro. Pero aún no era el momento, él empezaba a conocer a alguien, y ella aún no estaba preparada. Dicen que lo bueno se hace esperar. De modo que nunca llegaron a verse.",
            description5:"Hasta que...",
            description6:"...dos años más tarde, volvieron a reencontrarse.",
            description7:"¡Cuánto tiempo! - escribió José Luis. ",
            description8:"¿Perdona? ¿Me recuerdas? - respondió Leticia asombrada.",
            description9:"Claro, ¡recuerdo a todas las chicas que he besado! - dijo él con su clásica bravuconería.  ",
            description10:"Perdona, pero técnicamente... Yo te besé a ti – le bajó ella los humos.",
            description11:"Y desde entonces, desde ese 1 de febrero del 2019, no dejaron de vacilarse (y quererse) nunca más.  ",
        },
        {
            Id: '2',
            sImg: sImg2,
           // sTitle:'First Date',
           // date:'Sep 15,2022',
            description:"¡Porque son los animales más tiernos del planeta!",
            description2: "No, bromas aparte. ¿Sabíais que las nutrias son de los animales más fieles a su pareja? A los dos años ya escogen la que será su pareja para toda la vida. Curiosamente, nos tuvimos que dar dos años para reencontrarnos y elegirnos. Más aún, las nutrias marinas, tienen un gesto muy adorable al dormir en el agua, y es que duermen abrazadas para evitar que las separe la corriente. Quien nos conoce, sabe que detrás de nuestra aparente capa de seriedad, somos muy mimosos... Y es por todas estas semejanzas que empezaron a llamarnos los nutrios, montamos nuestra nutriguera donde convivir, pagamos con la nutrijeta y ahora os invitamos a compartir uno de los mejores días de nuestra vida; nuestra Nutriboda.",
            description3: "Una última curiosidad. José Luis, le regaló a Leticia su primer regalo de Navidad juntos por medio de una aplicación que diseñó él mismo. En esta app, debía resolver un quizz lleno de curiosidades sobre nutrias. ¿No es adorable?",
        },
        {
            Id: '3',
            sImg: sImg3,
            sIcon:sIcon3,
            Shape:Shape,
            sTitle:'Marige Proposal',
            date:'Sep 15,2022',
            description:'I must explain to you how all this mistaken idea of denouing pleasure and praising pain was born and I will give you com acount of system,the actual teach'
        },
        {
            Id: '4',
            sImg: sImg4,
            sIcon:sIcon4,
            Shape:Shape,
            sTitle:'Our Engagement',
            date:'Sep 15,2022',
            description:'I must explain to you how all this mistaken idea of denouing pleasure and praising pain was born and I will give you com acount of system,the actual teach'
        }
    ]



    return (

        <section className="wpo-story-section section-padding" >
            <div className="container">
                <SectionTitle MainTitle={'Nuestra historia'}/>
                <div className="row align-items-center justify-content-center">
                    <div className="col col-lg-12 col-12">
                        <div className="tab-area" id="story">
                            <div className="tablinks">
                                <Nav tabs className="service-thumbs-outer">
                                    {Tabs.map((tab, titem) => (
                                        <NavItem key={titem}>
                                            <NavLink
                                                className={`service-thumb ${classnames({ active: activeTab === tab.Id })}`}
                                                onClick={() => { toggle(tab.Id); }}
                                            >
                                                {tab.tabItem}
                                                <span className="number">{tab.tabNumber}</span>
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                            </div>
                            <TabContent activeTab={activeTab}>
                                {Story.map((story, Sitem) => (
                                    <TabPane tabId={story.Id} key={Sitem}>
                                        <div className="wpo-story-item">
                                            <div className="wpo-story-img">
                                                <img src={story.sImg} alt=""/>
                                            </div>
                                            <div className="wpo-story-content">
                                                <div className="wpo-story-content-inner">
                                                    <p>{story.description}</p>
                                                    <p>{story.description2}</p>
                                                    <p>{story.description3}</p>
                                                    <p>{story.description4}</p>
                                                    <p>{story.description5}</p>
                                                    <p>{story.description6}</p>
                                                    <p>{story.description7}</p>
                                                    <p>{story.description8}</p>
                                                    <p>{story.description9}</p>
                                                    <p>{story.description10}</p>
                                                    <p>{story.description11}</p>

                                                    <div className="border-shape">
                                                        <img src={story.Shape} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                ))}
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default StorySection;