import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import coupleImg1 from '../../images/couple/1.jpg'
import SectionTitle from "../SectionTitle";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import Simg from "../../images/section-title.png";
import imageHowToArrive from "../../images/COMOLLEGAR.png";

class CoupleSection extends Component {

    render() {
        return(
            <section className="wpo-story-section section-padding" id="couple">
                <div className="container">
                    <SectionTitle MainTitle={'Como llegar'}/>
                    <div className="container">

                        <div style={{ paddingRight: "20px", paddingLeft: "20px", textAlign: "ce"}} className="container" >

                            Se facilitará el desplazamiento en <b>autocar</b> desde Barcelona, con salida de plaza F. Macià a las 11:00 de la mañana y retorno a las 23:30 hacia la misma localización de origen.
                            <br/> <br/>También habrá servicio de autocar para los invitados que deseéis hospedaros en Sant Hilari de Sacalm (ida y vuelta) a Mas Can Ferrer. El horario y localización están por determinar.

                        </div>
                        <br/>
                        <br/>
                        <div className="container" style={{ maxWidth: "600px"}}>
                                <img src={imageHowToArrive} />

                        </div>
                    </div>
                </div>
            </section>

        );
    }
}
 
export default CoupleSection;