import React from 'react'
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/event/1.jpg'
import sImg2 from '../../images/event/2.jpg'
import sImg3 from '../../images/event/3.jpg'
import LocationMap from '../Modal'


const Events = [
    {
        Simg: sImg1,
        title:'The Reception',
        li1:'Monday, 25 Sep, 2022 1:00 PM – 4:30 PM',
        li2:'Estern Star Plaza, Road 123, USA',
    },
    {
        Simg: sImg2,
        title:'THE CEREMONY',
        li1:'Monday, 25 Sep, 2022 1:00 PM – 4:30 PM',
        li2:'Estern Star Plaza, Road 123, USA',
    },
    {
        Simg: sImg3,
        title:'Wedding Party',
        li1:'Monday, 25 Sep, 2022 1:00 PM – 4:30 PM',
        li2:'Estern Star Plaza, Road 123, USA',
    },

]

const EventSection = (props) => {
    return (
            <div style={{ paddingRight: "20px"}} className="container" id="events">
                <SectionTitle MainTitle={'Alojamiento recomendado'} />
                <table >
                    <tbody>
                    <tr style={{ border: "solid" }}>

                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>Nombre      </td>
                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>Ubicación</td>
                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>Precio</td>
                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>Contacto</td>
                    </tr>
                    <tr style={{ border: "solid" }}>
                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>Hotel Ripoll (3*)</td>
                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>Sant Hilari</td>
                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>Habitaciones dobles (precio por noche):<br /> Econ&oacute;mica - 53,25&euro; <br />B&aacute;sica - 65,25&euro; <br />Superior - 75,25&euro;</td>
                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>www.hotelripoll.com</td>
                    </tr>
                    <tr style={{ border: "solid" }}>
                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>Vilar Rural (4*)</td>
                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>Sant Hilari</td>
                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>61,25&euro;/pax <br />(Noche + desayuno incluido)*<br /> (*) Tiene aplicado un descuentov&aacute;lido hasta reservas previas al 15 de febrero del 2024</td>
                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>Escribir un mail a reserves@vilarsrurals.com o llamar al 93 511 03 00 y hacer la reserva con concepto &ldquo;Boda Leticia y Jos&eacute; Luis&rdquo;</td>
                    </tr>
                    <tr style={{ border: "solid" }}>
                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>Balneari Font Vella (4*)</td>
                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>Sant Hilari</td>
                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>Habitaciones dobles (precio por noche): <br />230&euro; <br />(incluye desayuno buffet y visita al spa)</td>
                        <td style={{ paddingRight: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingTop: "10px"}}>www.balnearifontvella.cat</td>
                    </tr>

                    </tbody>
                </table>
            </div>
    )
}

export default EventSection;